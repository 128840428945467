
let newsUl = 'news'; // classname
let calendarUl = 'agenda'; // classname
let maxNewsItems = 3;
let maxCalendarItems = 3;
const apiCall = {
    init() {
        fetch("/getdata.php").then(response => response.json())
        .then(data => {
            this.setNews(data.articles);
             this.setCalendar(data.calendar);
        })
        .catch((error) => {
        console.error('Error:', error);
        });
    },
    setNews(articles) {
        let items = 1;
        let newsElm = document.getElementsByClassName(newsUl)[0];
        newsElm.querySelectorAll('*').forEach(n => n.remove());
        articles.forEach(article => {
            if(items <= maxNewsItems) {
                let newLi = document.createElement('li');
                let newA = document.createElement('a');
                let text = document.createTextNode(article.title);
                newA.href = article.link;
                newA.target = '_blank';
                    newA.appendChild(text);

                newLi.appendChild(newA);
                newsElm.appendChild(newLi);
                items++;
            } else {
                return;
            }
        });
    },
    setCalendar(calItems) {
        let items = 1;
        let calendarElm = document.getElementsByClassName(calendarUl)[0];
        calendarElm.querySelectorAll('*').forEach(n => n.remove());
        calItems.forEach(item => {
            if(item.sourcetype == 'calendar' && items <= maxNewsItems) {

                let monthNames = ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];
                let href = (item.link !== null) ? item.link : 'javascript:;';
                let target = (item.link !== null) ? '_blank' : '_self';
                let linkClass = (item.link !== null) ? '' : ' no-hand-cursor';

                let timeFrame = (item.timefrom !== '00:00' && item.timeto !== '23:59') ? (item.timefrom + ' - '  + item.timeto) : '';
                let itemDate = new Date(item.datefrom);

                let template = '<li class="agenda__date">' +
                                  '<div class="agenda__date_info">' +
                                      '<span class="agenda__day">' + itemDate.getDate() + '</span>' +
                                      '<span class="agenda__month">' + monthNames[itemDate.getMonth()] + '</span>' +
                                      '<span class="agenda__time">' + timeFrame +  '</span>' +
                                  '</div>' +
                                  '<div>' +
                                     '<a href="' + href + '" target="' + target + '" class="agenda__title' + linkClass + '">' + item.title + '</a>' +
                                  '</div>' +
                               '</li>';


                calendarElm.insertAdjacentHTML('beforeend',template);
                items++;
            } else {
                return;
            }
        });

    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    apiCall.init();
});
