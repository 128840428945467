const $body = document.getElementsByTagName('body')[0];

var last_known_scroll_position = 0;
var ticking = false;
var minScroll = 120;

function scrollFrameHandler(scroll_pos, scroll_direction) {
	$body.classList.add('is-scrolling');

	if (scroll_direction === 'down') {
		$body.classList.add('goes-down');
		$body.classList.remove('goes-up');
	} else if (scroll_direction === 'up') {
		$body.classList.add('goes-up');
		$body.classList.remove('goes-down');
	}

	if (scroll_pos > minScroll) {
		$body.classList.add('has-scrolled');
	} else {
		$body.classList.remove('has-scrolled');
	}
}
window.addEventListener('scroll', function(e) {
	var new_scroll_position = window.pageYOffset;

	if (!ticking) {
		var scroll_direction = (new_scroll_position > last_known_scroll_position) ? 'down' : 'up';

		window.requestAnimationFrame(function() {
			scrollFrameHandler(last_known_scroll_position, scroll_direction);
			ticking = false;
		});

		ticking = true;
		last_known_scroll_position = new_scroll_position;
	}
});
